/*! -----------------------------------------------------------------------------------

    Template Name: Backoffice Admin
    Template URI: http://admin.pixelstrap.com/backoffice/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***
@import '../scss/utils/variables';

:root {
  // --theme-deafult: #7366ff;
  // --theme-secondary: #FF3364;
  --theme-deafult: #84a7cd;
  --theme-secondary: #214178;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2f2f3b;
  --chart-border: #ecf3fa;
  --recent-chart-bg: #fcfcfd;
  --light-bg: #f6f7f9;
  --white: #fff;
  --light2: #f5f6f9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #f2f4f7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #f2f3f7;
  --course-bg: #f7f7f7;
  --balance-profie-bg: #e0dfef;
  --view-grid-bg: #ecf3fa80;
  --view-border-marker: #cfcdfc;

  --fc-button-bg-color: var(--theme-deafult);
  --fc-button-active-bg-color: var(--theme-deafult);
  --fc-button-active-border-color: var(--theme-deafult);
  --fc-button-border-color: var(--theme-deafult);
  --fc-button-hover-bg-color: var(--theme-deafult);
  --fc-button-hover-border-color: var(--theme-deafult);
}

body[class='dark-only'] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --bs-tertiary-bg: #1d1e26;
  --bs-border-color: #374558;
  --bs-modal-footer-border-color: #374558;
  --bs-secondary-bg: #1d1e26;
}

body[class='rtl dark-only'] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --bs-tertiary-bg: #1d1e26;
  --bs-border-color: #374558;
  --bs-modal-footer-border-color: #374558;
  --bs-secondary-bg: #1d1e26;
}

body.dark-only.box-layout {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --bs-tertiary-bg: #1d1e26;
  --bs-border-color: #374558;
  --bs-modal-footer-border-color: #374558;
  --bs-secondary-bg: #1d1e26;
}

/* ---------------------
	*** Icons Scss ***
-----------------------*/
@import 'vendors/whether-icon';
@import 'vendors/flag-icon';
@import 'vendors/icofont';
@import 'vendors/themify';
@import 'vendors/bootstrap/accordion';
/* ---------------------
	*** Base ***
-----------------------*/
@import 'base/reset';
@import 'base/typography';

//  *** components ***
@import '../scss/pages/custom.scss';
@import 'components/according.scss';
@import 'components/alert.scss';
@import 'components/avatars.scss';
@import 'components/badge.scss';
@import 'components/bookmark.scss';
@import 'components/breadcrumb.scss';
@import 'components/builders.scss';
@import 'components/buttons.scss';
@import 'components/card.scss';
@import 'components/color.scss';
@import 'components/datatable.scss';
@import 'components/datepicker.scss';
@import 'components/dropdown.scss';
@import 'components/form-builder.scss';
@import 'components/form-input.scss';
@import 'components/form-wizard';
@import 'components/forms.scss';
@import 'components/form_builder-2.scss';
@import 'components/icons.scss';
@import 'components/list.scss';
@import 'components/loader.scss';
@import 'components/popover.scss';
@import 'components/print.scss';
@import 'components/radio.scss';
@import 'components/ribbon.scss';
@import 'components/switch.scss';
@import 'components/tab.scss';
@import 'components/table.scss';
@import 'components/touchspin.scss';
@import 'components/tour.scss';
@import 'components/tree.scss';
@import 'components/typeahead-search.scss';
@import 'components/scrollbar.scss';
@import 'components/ngx-datatable';

//	*** pages ***

@import 'pages/blog.scss';
@import 'pages/bookmark-app.scss';
@import 'pages/cart.scss';
@import 'pages/chart.scss';
@import 'pages/chat.scss';
@import 'pages/checkout.scss';
@import 'pages/comingsoon.scss';
@import 'pages/contacts.scss';
@import 'pages/dashboard_2.scss';
@import 'pages/dashboard_3.scss';
@import 'pages/dashboard_4.scss';
@import 'pages/dashboard_5.scss';
@import 'pages/dashboard_default.scss';
@import 'pages/ecommerce.scss';
@import 'pages/email-application.scss';
@import 'pages/errorpage.scss';
@import 'pages/faq.scss';
@import 'pages/file.scss';
@import 'pages/gallery.scss';
@import 'pages/internationalization.scss';
@import 'pages/job-search.scss';
@import 'pages/jsgrid.scss';
@import 'pages/kanban.scss';
@import 'pages/knowledgebase.scss';
@import 'pages/language.scss';
@import 'pages/learning.scss';
@import 'pages/login.scss';
@import 'pages/megaoption.scss';
@import 'pages/order-history.scss';
@import 'pages/page.scss';
@import 'pages/pricing.scss';
@import 'pages/progress.scss';
@import 'pages/projectlist.scss';
@import 'pages/social-app.scss';
@import 'pages/task.scss';
@import 'pages/timeline-v.scss';
@import 'pages/timeliny.scss';
@import 'pages/user-profile.scss';
@import 'pages/wishlist.scss';
@import 'pages/promotion-management.scss';
@import 'pages/page-configuration.scss';

/* ---------------------
	*** themes ***
-----------------------*/
@import 'themes/dark.scss';
@import 'themes/theme-customizer.scss';
@import 'themes/update.scss';
// *** layout ***

@import 'layout/footer.scss';
@import 'layout/grid.scss';
@import 'layout/header.scss';
@import 'layout/navs.scss';
@import 'layout/search.scss';
@import 'layout/select2.scss';
@import 'layout/sidebar.scss';
@import 'layout/rtl.scss';
@import 'layout/box-layout.scss';
@import 'layout/_real-time.scss';

.casino-list-games {
  border: solid 1px #ccc;
  min-height: 60px;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}

.casino-box-games {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
}

.casino-list-providers {
  max-height: 500px;
  overflow-y: auto;
  height: 500px;
}

.casino-box-providers {
  display: flex;
  align-items: center;
  background-color: var(--light-bg);
  border: 1px solid var(--recent-chart-bg);
  padding: 10px;
  border-radius: 8px;
  cursor: move;
  transition: background-color 0.3s ease;
}

.casino-providers-grid {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  gap: 10px;
}

.casino-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .casino-providers-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .casino-list-providers {
    max-height: 200px;
    overflow-y: auto;
    height: 200px;
  }

  .casino-buttons {
    flex-direction: row;
  }
}

.appRequiredAsterisk {
  color: #dc3545 !important;
}

.skeleton {
  background: linear-gradient(
    90deg,
    var(--light-background),
    var(--recent-chart-bg),
    var(--light-background)
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.text-skeleton {
  width: 70%;
  height: 20px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.mini-card {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, var(--white) 0%, var(--light2) 100%);
  position: relative;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
}

.mini-card:hover {
  transform: translateY(-5px);
}

.mini-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 300%;
  height: 300%;
  background: repeating-radial-gradient(
    circle,
    var(--white) 0px,
    var(--light2) 30px,
    transparent 6px,
    transparent 25px
  );
  transform: rotate(-15deg);
  opacity: 0.25;
  pointer-events: none;
}

.mini-card-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}

.mini-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.svg-yellow {
  fill: #ffcc00 !important;
}

.svg-blue {
  fill: #007bff !important;
}

.svg-green {
  fill: #28a745 !important;
}

.svg-red {
  fill: #dc3545 !important;
}

.svg-purple {
  fill: #6f42c1 !important;
}

.live-gamepad {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 4px;
  background-color: red;
  border-radius: 100px;
}

.fa-icon-md-size {
  font-size: 20px;
}

.user-avatar {
  width: 60px;
  height: 60px;
}

.info-item {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.info-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.image-card {
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.image-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.image-card:hover .image-overlay {
  opacity: 1;
}

.image-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.image-controls {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}