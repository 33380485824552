.ngx-datatable.material {
  box-shadow: none;
  border-radius: 4px;
  background-color: $card-body-bg-color;

  .datatable-header,
  .datatable-body,
  .datatable-footer {
    width: auto !important;
    height: auto !important;
  }

  .datatable-header .datatable-row-left,
  .datatable-body .datatable-row-left {
    background-image: none !important;
    background-repeat: no-repeat !important ;
    transform: none !important;
    position: sticky;
    left: 0;
    .datatable-header-cell,
    .datatable-body-cell {
      border-right: 1px solid $light-semi-gray;
      width: 100% !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .datatable-header .datatable-row-right,
  .datatable-body .datatable-row-right {
    background-image: none !important;
    background-repeat: no-repeat !important ;
    transform: none !important;
    position: sticky;
    right: 0;
    .datatable-header-cell,
    .datatable-body-cell {
      border-left: 1px solid $light-semi-gray;
      width: 100% !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .datatable-body .progress-linear .container {
    max-width: none;
    background-color: $primary-color;
    .bar {
      background-color: $secondary-color;
    }
  }

  .datatable-body .datatable-body-row {
    &:hover .datatable-row-group {
      background-color: $light-background;
    }

    &:focus .datatable-row-group {
      background-color: $light-background;
    }

    &.active .datatable-row-group {
      background-color: $light-background;
    }

    &.active:hover .datatable-row-group {
      background-color: $light-background;
    }
  }

  .datatable-row-wrapper .datatable-row-detail {
    height: auto !important;
  }

  .datatable-row-wrapper .datatable-row-detail {
    height: auto !important;
  }

  .datatable-body .datatable-summary-row .datatable-body-row {
    height: auto !important;
    background-color: transparent !important;
  }

  .datatable-body
    .datatable-summary-row
    .datatable-body-row
    .datatable-body-cell {
    height: auto !important;
  }

  .datatable-footer .datatable-footer-inner {
    flex-wrap: wrap;
    height: auto !important;
    overflow: hidden;

    .footer-left,
    .datatable-pager {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .footer-left {
      .page-size-container,
      .total-rows-container {
        color: inherit;
        padding: 8px;
      }
    }

    .datatable-pager {
      flex: 1 30% !important;
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ngx-datatable.material {
    .datatable-footer .datatable-footer-inner {
      flex-direction: column;

      .footer-left,
      .datatable-pager {
        width: 100%;
      }
      .footer-left {
        order: 1;
        justify-content: space-between;
      }

      .datatable-pager {
        order: 2;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
