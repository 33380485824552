/**=====================
      08. GRID CSS Start
==========================**/
.grid-showcase {
  margin-bottom: -10px;

  span {
    display: block;
    border: 1px solid $light-color;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: $white;
  }
}

.grid-align {
  margin-bottom: -30px;

  .row {
    padding: 15px 0;
    min-height: 10rem;
    border: 1px solid rgba($primary-color, 0.02);
    margin-bottom: 30px;
    background-color: rgba($primary-color, 0.02);
  }
}

/**=====================
      08. GRID CSS Ends
==========================**/

.grid-container {
  display: grid;
  grid-template-columns: 50% 30% 17.8%;
  grid-gap: 15px;
}
.grid-item {
  width: 100%;
}
.grid-container-second-row {
  display: grid;
  grid-template-columns: 81.2% 17.8%;
  grid-gap: 15px;
}
.checkbox label {
  padding-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 900px) {
  .grid-container,
  .grid-container-second-row {
    grid-template-columns: 100%;
  }

  .grid-item-full {
    grid-column: span 1;
  }
}

@media (min-width: 991px) {
  .grid-container {
    grid-template-columns: 50% 30% 17.8%;
  }

  .grid-item-full {
    grid-column: span 1;
  }
}
