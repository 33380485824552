.crm-flow-sidebar-block {
	display: flex;
	align-items: center;
	background-color: (white);
	overflow: hidden;
	width: 100%;
	height: 42px;
	margin-bottom: 20px;
	border-radius: 10px;
	border: 1px solid (white);
	color: rgba(black, .75);
	cursor: move;
}

.crm-flow-sidebar-block div {
	display: flex;
	justify-content: center;
	min-width: 42px;

	color: white;
	height: 100%;
	align-items: center;
}

.crm-flow-sidebar-block span {
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 124px;
	overflow: hidden;
	padding-right: 4px;
	margin-left: 20px;
	font-size: 14px;
	font-weight: 600;
	color: rgba(var(--background-sc-rgb), .75);
}

.crm-flow-editor-block {
	position: relative;
	width: 220px;
	height: 60px;
	border-radius: 10px;
	background-color: var(--background);
	border: 1px solid var(--line-color);
}

.crm-flow-editor-block .crm-flow-editor-block-body {
	height: 100%;
}

.crm-flow-editor-block .crm-flow-editor-block-body .crm-flow-editor-block-content {
	display: flex;
	height: 100%;
	border-radius: 9px;
	overflow: hidden;
}

.crm-flow-editor-block .crm-flow-editor-block-body .crm-flow-editor-block-content>div:first-child {
	width: 42px;
	min-width: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: green;
}

.crm-flow-editor-block .crm-flow-editor-block-body .crm-flow-editor-block-content>div:last-child {
	margin-right: auto;
	margin-left: 12px;
	color: rgba(0, 0, 0, .75);
}

.input-trasnparent {
	border: 0px;
	font-size: 16px;
	height: 22px;
	line-height: 1.38;
}

.crm-flow-editor-block .crm-flow-editor-block-body .crm-flow-editor-block-content>div:last-child .crm-flow-editor-block-title>input {
	display: block;
	width: 125px;
	margin-top: 10px;
	padding-right: 12px;
	font-size: 14px;
	font-weight: 600;
	line-height: 1.36;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 0px;
	white-space: nowrap;
}

.crm-flow-editor-block .crm-flow-editor-block-body .crm-flow-editor-block-content>div:last-child span {
	display: block;
	width: 125px;
	margin-top: 4px;
	padding-right: 12px;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.42;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.custom-button-drawflow {
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	top: 45px;
	left: 160px;
}

.custom-button-drawflow i {
	font-size: 12px;
}

.drawflow .drawflow-node {
	background: #ffffff;
	border: 1px solid #cacaca;
	-webkit-box-shadow: 0 2px 15px 2px #cacaca;
	box-shadow: 0 2px 15px 2px #cacaca;
	// padding: 0px;
	width: 200px;
	padding: 0px;
	border-radius: 20px;
}

.drawflow .drawflow-node.selected {
	background: white;
	border: 1px solid #4ea9ff;
	-webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
	box-shadow: 0 2px 20px 2px #4ea9ff;
}

.drawflow .drawflow-node .input {
	left: -15px;
	top: 2px;
	background: white;
}

.drawflow .drawflow-node .output {
	right: 5px;
	top: 2px;
}

@media (min-width: 1600px) {
	.modal-xl {
		--bs-modal-width: 1200px !important;
	}
}