.page__config__content {
  margin: 40px 40px 0 40px;
}

.content-card {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  padding: 0;
  transition: transform 0.4s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.background-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
  font-size: clamp(100px, 30vw, 200px);
  color: rgba(0, 0, 0, 0.05);
  pointer-events: none;
  transition: opacity 0.4s ease, transform 0.4s ease;
  opacity: 0;
  transform: scale(0.8);
  z-index: 1;
}

.background-icon.show {
  opacity: 1;
  transform: scale(1);
}

@media screen and (max-width: 576px) {
  .custom-card {
    width: 150px;
    height: 150px;
  }

  .background-icon {
    bottom: -200px;
    display: none;
  }

  .content-card {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .content-card {
    justify-content: center;
    align-items: center;
  }
}

/* Code Editor */
.editor-container {
  border: 1px solid #ddd;
  font-family: monospace;
  height: 150px;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px 30px;
}
