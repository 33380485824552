
.upload {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  position: relative;
  margin-top: 10px;
  border-radius: 10px;
}

.image-preview {
  max-height: 350px;
  max-width: 95%;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.img-hover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.img-hover img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.vh-50 {
  height: 50vh !important;
}

// .img-fluid {
//   max-width: 400px !important;
// }
