/* You can add global styles to this file, and also import other style files */
@import './assets/scss/app.scss';

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.save-btn {
  transform: scale(1);
  animation: pulse 0.9s infinite;

  &:disabled {
    animation: none;
  }
}

.divider:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}

.flex-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-section-accordion {
  .accordion-body > .fa-trash-o {
    display: none;
  }

  .accordion-header {
    position: relative;

    .btn-remove-slide {
      position: absolute;
      top: 50%;
      right: 48px;
      transform: translate(-10px, -50%);
      z-index: 4;
    }

    &__title {
      font-weight: bold;
      color: var(--body-font-color);
      font-size: 0.85rem;
    }
  }
}

.hero-casino-form {
  .form-row-container {
    border-top: 1px solid #e9ecef;
    padding-top: 1rem;
  }

  .form-row-container:first-child {
    border-top: none;
  }
}
