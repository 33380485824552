/**=====================
    25. Authentication CSS Start
==========================**/
:root {
  --overlay-color-1: rgba(39, 96, 151, 0.5); /* Color oscuro con opacidad */
  --overlay-color-2: rgba(99, 192, 201, 0.5); /* Color claro con opacidad */
}

.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  // background: url("../../images/login/login_bg.jpg");
  background-position: center;
  padding: 30px 12px;

  .logo {
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }

  .btn-showcase {
    .btn {
      line-height: 1;
      padding: 10px 15px;
      margin: 0;

      & + .btn {
        margin-left: 5px;
      }

      svg {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }

  .login-main {
    width: 450px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: $white;

    .theme-form {
      h4 {
        margin-bottom: 5px;
      }

      label {
        font-size: 15px;
        letter-spacing: 0.4px;
      }

      .checkbox label::before {
        background-color: $card-body-color;
        border: 1px solid #dfdfdf;
      }

      .or {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 65%;
          height: 2px;
          background-color: #f3f3ff;
          top: 9px;
          z-index: 0;
          right: 0;
        }
      }

      input {
        background-color: #f3f3ff;
        transition: all 0.3s ease;

        &::-webkit-input-placeholder {
          color: $light-text;
        }

        &:hover,
        &:focus {
          border: 1px solid lighten($semi-dark, 35%);
          box-shadow: none !important;
          transition: all 0.3s ease;
        }
      }

      p {
        margin-bottom: 25px;
        font-size: 14px;
        color: $dark-gray;
      }

      .form-group {
        margin-bottom: 10px;
        position: relative;
      }

      .link {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

.show-hide {
  position: absolute;
  top: 52px;
  right: 20px;
  transform: translateY(-50%);

  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-deafult);

    &.show {
      &:before {
        content: "show";
      }
    }

    &:before {
      content: "hide";
    }
  }
}

.needs-validation {
  .invalid-feedback {
    color: $dark-gray;
  }

  .show-hide {
    right: 30px;
  }

  .invalid-tooltip {
    top: 10px;
    right: 10px;
  }
}

.background-color-left {
  background-image: url("../../../assets/images/login/login_bg.jpg");
}

.background-color-left::before {
  content: "";

  background: linear-gradient(
    120deg,
    var(--overlay-color-1),
    var(--overlay-color-2)
  );
  mix-blend-mode: overlay; /* Aplica el gradiente sobre la imagen de fondo */
}

.title-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  line-height: 2.2rem;
}

.typing-effect {
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  font-size: 2.3rem;
  margin-bottom: 1rem;
  background: linear-gradient(40deg, #276097 5%, #63c0c9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  border-right: 0.15em solid #276097;
  white-space: nowrap;
  animation: typing 4s steps(15, end) forwards,
    blink-caret 0.75s step-end infinite;
  width: 0;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 12.3ch;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #63c0c9;
  }
}

@media screen and (min-width: 1200px) {
  .background-color-left {
    display: flex !important;
  }
}
/**=====================
    25. Authentication CSS Ends
==========================**/

